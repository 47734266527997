import { v4 as uuidv4 } from "uuid"
import { size, remove } from "lodash";
import { TWEETS } from "../utils/constants";


export function saveTweetAPI(tweet, username){
  const tweets = getTweetsAPI();

  if(size(tweets) === 0){
    const objTweet = [{
      id: uuidv4(),
      tweet,
      username,
      createdAt: new Date(),
    }];
    localStorage.setItem(TWEETS, JSON.stringify(objTweet));  
  }else{
    tweets.push({
      id: uuidv4(),
      tweet,
      username,
      createdAt: new Date(),
    });
    localStorage.setItem(TWEETS, JSON.stringify(tweets));  
  }
}


export function getTweetsAPI(){
    const tweets = localStorage.getItem(TWEETS);
    if (tweets){
      return JSON.parse(tweets);
    }
    return [];
}


export function deleteTweetAPI(idTweet){
  const tweets = getTweetsAPI();

  remove(tweets, function(tweet){
    return tweet.id === idTweet;
  });

  localStorage.setItem(TWEETS, JSON.stringify(tweets));
}