import "bootstrap/dist/css/bootstrap.min.css"

import { createApp } from "vue";
import App from './App.vue'


//Mounting the APP
createApp(App).mount('#app');

import "bootstrap/dist/js/bootstrap.js";

/*new Vue({
    render: h => h(App)
}).$mount('#app');*/
