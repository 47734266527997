<script>
    import { ref } from "vue";
    import { saveTweetAPI } from "../api/tweet";

    export default {
        props: {
            showForm: Boolean,
            openCloseForm: Function,
            reloadTweets: Function,
        },

        setup(props){
            let username = ref('');
            let tweet = ref('');

            const sendTweet = () => {
                if (!tweet.value || !username.value) return;
                
                saveTweetAPI(tweet.value, username.value);

                tweet.value = '';
                username.value = '';

                props.reloadTweets();                
                props.openCloseForm();
            }
            return { sendTweet, username, tweet };
        },
    }
</script>

<template>
    <div class="tweet-form container" :class="{ open: showForm }">
        <form @submit.prevent="sendTweet">
            <input type="text" v-model="username" class="form-control" placeholder="Tu nombre">
            <textarea v-model="tweet" cols="30" rows="3" class="form-control" placeholder="Escribe tu nombre"></textarea>
            <button type="submit" class="btn btn-primary">
                Send Tweet
            </button>
        </form>
    </div>
</template>



<style lang="scss" scoped>
    .tweet-form {
        margin-top: 20px;
        overflow: hidden;
        height:0px;

        &.open {
            height:auto;            
        }

        form{
            margin-bottom: 50px;

            input {
                margin-bottom: 10px;
            }

            button{
                width:100%;
                margin-top: 10px;
            }
        }
    }
</style>