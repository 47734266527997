<template>
      <Menu :openCloseForm="openCloseForm" :showForm="showForm"/>  
      <TweetForm 
        :showForm="showForm" 
        :openCloseForm="openCloseForm" 
        :reloadTweets="reloadTweets"/> 

      <TweetList :tweets="tweets" :reloadTweets="reloadTweets"/>

</template>

<script>
  import { ref } from 'vue';
  import Menu from "./components/Menu";
  import TweetForm from "./components/TweetForm";
  import TweetList from "./components/TweetList";
  import useFormTweet from "./hooks/useFormTweet";
  import { getTweetsAPI } from "./api/tweet";

  export default {
    name: 'App',
    components: {
      Menu,
      TweetForm,      
      TweetList,
    },

    setup(){
      let tweets = ref(getTweetsAPI().reverse());

      const reloadTweets = () => {
        tweets.value = getTweetsAPI().reverse();
      };

      return {
        ...useFormTweet(),
        tweets,
        reloadTweets,
      }
    }
  }
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //margin-top: 60px;
}
</style>
